<div class="content-container">
    <mat-card class="content-card">
        <mat-card-content>
            <app-form [newForm]="fileNewForm"></app-form>
        </mat-card-content>
        <mat-card-actions class="card-actions" style="display: flex; justify-content: flex-end;">
            <button type="button" mat-raised-button color="primary" class="action-button"
                [disabled]="fileFormGroup.invalid || saved" (click)="saveChanges()">
                Actualizar
            </button>
            <button type="button" mat-raised-button (click)="cancelEdit()" class="action-button">
                Cancelar
            </button>
        </mat-card-actions>
    </mat-card>
</div>