import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '@services/navigation.service';

@Component({
  selector: 'app-logout',
  standalone: true,
  imports: [],
  template: `
    <div class="box">
      <p>
        Tu sesión se ha cerrado. Serás redireccionado a Inicio de Sesión en {{countdown}} segundos
      </p>
    </div>
  `,
  styles: `
    .box {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `
})
export class LogoutComponent implements AfterViewInit {
  countdown = 5;
  constructor(
    private readonly navService: NavigationService,
    private readonly router: Router,
  ) { }

  ngAfterViewInit(): void {      
    const interval = setInterval(() => {
      this.countdown--;
        
      if (this.countdown === 0 ) {
        clearInterval(interval);
        this.redirect();
      }
    }, 1000);
  }

  redirect() {
    this.navService.setLoggedIn(false);
    this.router.navigate(['login']);
  }
}
