import { Routes } from "@angular/router";
import { CmsComponent } from "./cms/cms.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { cmsRoutes } from "./cms/cms.routes";
import { ProfileComponent } from "./profile/profile.component";

export const privateRoutes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
    },
    {
        path: 'cms',
        component: CmsComponent,
        children: cmsRoutes,
    },
    {
        path: 'profile',
        component: ProfileComponent,
    },
];
