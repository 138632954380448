<table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
  class="mat-elevation-z8">
  @for (column of displayedColumns; track column) {
    <ng-container [matColumnDef]="column">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column | titlecase }}</th>
      <td mat-cell *matCellDef="let element">
        @if (column === 'editar') {
          <button mat-icon-button (click)="editElement(element)">
            <mat-icon>edit</mat-icon>
          </button>
        } @else {
          {{ element[column] }}
        }
      </td>
    </ng-container>
  }
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
