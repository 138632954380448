import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormComponent } from '@components/manager/form/form.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NewForm } from '@models/form.model';
import { CmsService } from '@services/cms.service';
import { Location } from '@angular/common';
import { ContentModel } from '@models/content.model';

@Component({
  selector: 'app-edit',
  standalone: true,
  imports: [
    FormComponent,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
  ],
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.scss'
})
export class EditComponent implements OnInit {
  fileFormGroup: FormGroup;
  fileNewForm: NewForm;
  saved = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private cmsService: CmsService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.loadData(id);
    });
  }

  private loadData(id: string): void {
    const data = this.cmsService.getDataById(Number(id));
    if (data) {
      this.inicializarForm(data);
    } else {
      console.error('No se encontró el registro con ID:', id);
    }
  }

  private inicializarForm(data: ContentModel): void {
    this.fileFormGroup = this.fb.group({
      nombre: new FormControl(data.nombre, [Validators.required]),
      descripcion: new FormControl(data.descripcion, [Validators.required]),
      autor: new FormControl(data.autor, [Validators.required]),
      fecha: new FormControl(data.fecha, [Validators.required]),
      valor: new FormControl(data.valor, [Validators.required]),
    });

    this.fileNewForm = {
      title: 'Editar Información',
      formFields: [
        { target: 'nombre', title: 'Nombre', type: 'INPUT', size: 3 },
        { target: 'descripcion', title: 'Descripción', type: 'INPUT', size: 3 },
        { target: 'autor', title: 'Autor', type: 'INPUT', size: 3 },
        { target: 'fecha', title: 'Fecha', type: 'DATEPICKER', size: 3 },
        { target: 'valor', title: 'Valor', type: 'INPUT', size: 3 },
      ],
      newFormGroup: this.fileFormGroup,
    };
  }

  saveChanges(): void {
    if (this.fileFormGroup.valid) {
      this.saved = true;
      const updatedData = {
        ...this.fileFormGroup.value,
        id: Number(this.route.snapshot.params['id'])
      };
      this.cmsService.updateData(updatedData.id, updatedData);
      console.log('Registro actualizado exitosamente:', updatedData);
      this.location.back();
      this.saved = false;
    } else {
      console.error('El formulario no es válido');
    }
  }

  cancelEdit(): void {
    this.location.back();
  }
}
