import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatList, MatListItem, MatListItemIcon, MatNavList } from '@angular/material/list';
import { NavConfig } from '@models/nav-config.model';
import { SidenavListItemComponent } from '../sidenav-list-item/sidenav-list-item.component';

@Component({
  selector: 'app-sidenav-list',
  standalone: true,
  imports: [
    CommonModule,
    MatIcon,
    MatList,
    MatListItem,
    MatListItemIcon,
    MatNavList,
    SidenavListItemComponent,
  ],
  templateUrl: './sidenav-list.component.html',
  styleUrl: './sidenav-list.component.scss'
})
export class SidenavListComponent {
  @Input() showText = false;
  @Input() navConfig: NavConfig;
  @Output() showSubMenu = new EventEmitter();
  rotate = false;
  
  onClick() {
    this.rotate = !this.rotate;
    this.showSubMenu.emit();
  }

}
