<mat-toolbar color="primary">
  <button mat-icon-button aria-label="Menu icon" (click)="toggleMenu()">
    <mat-icon>menu</mat-icon>
  </button>
  <h1>MiBodega CMS</h1>
  <span class="spacer"></span>
  @if(isLoggedIn) {
    <button mat-button (click)="navigateTo('private/profile')" aria-label="Example icon-button with heart icon">
      Perfil
    </button>
    <button mat-button (click)="navigateTo('logout')" aria-label="Example icon-button with heart icon">
      Cerrar Sesión
    </button>
  } @else {
    <button mat-button (click)="navigateTo('login')" aria-label="Example icon-button with heart icon">
      Iniciar Sesión
    </button>
  }
</mat-toolbar>
