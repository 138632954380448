<div class="filter-container">
  @for (filter of filters; track filter; let i = $index) {
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Columna</mat-label>
        <mat-select [(ngModel)]="filter.column">
          @for (column of columns; track column) {
            <mat-option [value]="column">{{ column }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Filtro</mat-label>
        <input matInput [(ngModel)]="filter.value">
      </mat-form-field>
      <button mat-icon-button color="warn" (click)="removeFilter(i)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  }

  <button mat-button (click)="addFilter()">Agregar Filtro</button>
  <button mat-button color="primary" (click)="applyFilters()">Aplicar Filtros</button>
  <button mat-button color="warn" (click)="clearFilters()">Limpiar Filtros</button>
</div>