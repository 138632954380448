import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-private',
  standalone: true,
  imports: [
    RouterModule,
  ],
  template: `<router-outlet/>`,
  styleUrl: './private.component.scss'
})
export class PrivateComponent {
}
