@if (model.isLink) {
    <a mat-list-item [attr.aria-hidden]="true" (click)="navigateTo(model.target)">
        <mat-icon mat-list-icon>{{model.icon}}</mat-icon>
        @if(showText) {
            <span class="full-width" >{{model.label}}</span>
        }
    </a>
} @else {
    <mat-list-item (click)="onItemClicked()" class="parent">
        <mat-icon mat-list-icon>{{model.icon}}</mat-icon>
        @if(showText){
            <span class="full-width">{{model.label}}</span>
            <ng-content></ng-content>
        }
    </mat-list-item>
}