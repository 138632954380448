import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { ContentModel } from '@models/content.model';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  
  private dataSource = new MatTableDataSource([
    { id: 1, nombre: 'banner1.jpg', descripcion: 'Banner de promoción', autor: 'Admin', fecha: '2024-03-26', hora: '10:00 AM', valor: 'base64string1', categoria: 'Banners' },
    { id: 2, nombre: 'alerta1.txt', descripcion: 'Alerta de seguridad', autor: 'Admin', fecha: '2024-03-25', hora: '09:30 AM', valor: 'base64string2', categoria: 'Alertas' },
    { id: 3, nombre: 'imagen1.png', descripcion: 'Imagen de producto', autor: 'Admin', fecha: '2024-04-23', hora: '03:15 PM', valor: 'base64string4', categoria: 'Imagenes' },
    { id: 4, nombre: 'banner2.jpg', descripcion: 'Banner de oferta', autor: 'User', fecha: '2024-03-22', hora: '08:00 AM', valor: 'base64string5', categoria: 'Banners' },
    { id: 5, nombre: 'alerta2.txt', descripcion: 'Alerta de mantenimiento', autor: 'Admin', fecha: '2024-03-21', hora: '01:30 PM', valor: 'base64string6', categoria: 'Alertas' },
    { id: 6, nombre: 'info2.html', descripcion: 'Informacion de contacto', autor: 'Admin', fecha: '2024-04-20', hora: '10:45 AM', valor: 'base64string7', categoria: 'Informacion' },
    { id: 7, nombre: 'imagen2.png', descripcion: 'Imagen de fondo', autor: 'User', fecha: '2024-03-19', hora: '04:00 PM', valor: 'base64string8', categoria: 'Imagenes' },
    { id: 8, nombre: 'banner3.jpg', descripcion: 'Banner de evento', autor: 'Admin', fecha: '2024-04-18', hora: '11:00 AM', valor: 'base64string9', categoria: 'Banners' },
    { id: 9, nombre: 'alerta3.txt', descripcion: 'Alerta de nuevo producto', autor: 'User', fecha: '2024-03-17', hora: '09:00 AM', valor: 'base64string10', categoria: 'Alertas' },
    { id: 10, nombre: 'banner4.jpg', descripcion: 'Gran Venta de Verano', autor: 'Marketing', fecha: '2024-07-01', hora: '12:00 AM', valor: 'base64string11', categoria: 'Banners' },
    { id: 11, nombre: 'alerta4.txt', descripcion: 'Mantenimiento Programado del Servidor', autor: 'IT', fecha: '2024-04-08', hora: '12:00 AM', valor: 'base64string12', categoria: 'Alertas' },
    { id: 12, nombre: 'noticia1.html', descripcion: 'Nuevos Avances en la Tecnología de Baterías', autor: 'Research Team', fecha: '2024-04-10', hora: '09:00 AM', valor: 'base64string13', categoria: 'Noticias' },
    { id: 13, nombre: 'imagen3.png', descripcion: 'Paisaje Natural Montañas', autor: 'Nature Photographer', fecha: '2024-04-22', hora: '02:00 PM', valor: 'base64string14', categoria: 'Imagenes' },
    { id: 14, nombre: 'info1.html', descripcion: 'Conferencia Anual de Innovación', autor: 'Events Coordinator', fecha: '2024-05-15', hora: '08:00 AM', valor: 'base64string15', categoria: 'Informacion' },
    { id: 15, nombre: 'info3.html', descripcion: 'Webinar sobre Marketing Digital', autor: 'Marketing', fecha: '2024-06-20', hora: '03:00 PM', valor: 'base64string16', categoria: 'Informacion' },
    { id: 16, nombre: 'info4.html', descripcion: 'Taller de Desarrollo Sostenible', autor: 'CSR Manager', fecha: '2024-07-05', hora: '10:30 AM', valor: 'base64string17', categoria: 'Informacion' },
  ]);


  private dataChange: BehaviorSubject<ContentModel[]> = new BehaviorSubject<ContentModel[]>(this.dataSource.data);

  constructor() { }

  getData() {
    return this.dataChange.asObservable();
  }

  getDataById(id: number): ContentModel | undefined {
    return this.dataSource.data.find(d => d.id === id);
  }

  addData(newData: ContentModel): void {
    const currentData = this.dataSource.data;
    const maxId = currentData.reduce((max, item) => item.id > max ? item.id : max, 0);
    newData.id = maxId + 1;
    currentData.push(newData);
    this.dataSource.data = currentData;
    this.dataChange.next(this.dataSource.data);
  }

  updateData(id: number, newData: ContentModel): void {
    const index = this.dataSource.data.findIndex(d => d.id === id);
    if (index !== -1) {
      if (newData && newData.id) {
        this.dataSource.data[index] = { ...this.dataSource.data[index], ...newData };
        this.dataChange.next(this.dataSource.data);
      } else {
        console.warn('Los datos proporcionados para la actualización son inválidos, se mantienen los datos existentes.');
      }
    } else {
      console.warn('No se encontró ningún registro con el ID proporcionado.');
    }
  }

}
