import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatTableDataSource } from '@angular/material/table';
import { DataTableComponent } from '@components/data-table/data-table.component';
import { FilterComponent } from '@components/manager/filter/filter.component';
import { FormComponent } from '@components/manager/form/form.component';
import { ContentModel } from '@models/content.model';
import { NewForm } from '@models/form.model';
import { CmsService } from '@services/cms.service';

@Component({
  selector: 'app-content-manager',
  standalone: true,
  imports: [
    FormComponent,
    FilterComponent,
    DataTableComponent,
    MatCardModule,
    MatButtonModule
  ],
  templateUrl: './content-manager.component.html',
  styleUrl: './content-manager.component.scss'
})
export class ContentManagerComponent implements OnInit, OnChanges {
  @Input() displayedColumns: string[];
  @Input() dataSource: MatTableDataSource<ContentModel>;
  @Input() newForm: NewForm;
  @Input() formGroup: FormGroup;
  @Input() category: string;

  saved = false;

  constructor(private cmsService: CmsService) { }

  ngOnInit() {
    this.cmsService.getData().subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
    });
    this.applyFilters([]);
  }

  ngOnChanges(): void {
      this.applyFilters([]);
  }

  saveFile(): void {
    if (this.newForm.newFormGroup.valid) {
      this.saved = true;
      const newRecord = {
        ...this.newForm.newFormGroup.value
      };
      this.cmsService.addData(newRecord);
      console.log('Nuevo registro creado:', newRecord);
      this.newForm.newFormGroup.reset();
      this.saved = false;
    } else {
      console.error('El formulario no es válido');
    }
  }

  cancelCreate() {
    this.newForm.newFormGroup.reset();
  }

  applyFilters(filters: Array<{ column: string; value: string; }>) {
    const index = filters.findIndex(f => f.column === 'categoria');
    if (index === -1) {
      filters.push({ column: 'categoria', value: this.category });
    } else {
      filters[index].value = this.category;
    }

    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const transformedFilters: Array<{ column: string; value: string; }> = JSON.parse(filter);
      return transformedFilters.every(f => {
        const dataValue = data[f.column]?.toString().toLowerCase() || '';
        return dataValue.includes(f.value.toLowerCase());
      });
    };

    this.dataSource.filter = JSON.stringify(filters);
  }

  clearFilters() {
    const categoryFilter = [{ column: 'categoria', value: this.category }];
    this.dataSource.filter = JSON.stringify(categoryFilter);
  }
  
}
