import { Routes } from '@angular/router';
import { LoginComponent } from '@routes/login/login.component';
import { LogoutComponent } from '@routes/logout/logout.component';
import { PrivateComponent } from '@routes/private/private.component';
import { privateRoutes } from '@routes/private/private.routes';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'private',
        pathMatch: 'full',
    },
    {
        path: 'private',
        component: PrivateComponent,
        children: privateRoutes,
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'logout',
        component: LogoutComponent,
    },
    {
        /* 
            @Alexander: Crear componente not-found que avise 'Lo que estás buscando no existe. Serás redireccionado en 5'
                        y redireccionar con cuenta regresiva a dashboard.
        */
        path: '**',
        redirectTo: 'login', // Cambiar por not-found
    }
];
