<mat-sidenav-container class="new-container" autosize>
    <mat-sidenav #sidenav class="new-sidenav" mode="side" opened="true" (mouseenter)="mouseenter()"
        (mouseleave)="mouseleave()">
        <app-sidenav-list [showText]="isExpanded || isShowing" [navConfig]="config" (showSubMenu)="showSubMenu()"/>
        @if (isExpanded || isShowing) {
            <div style="padding-left: 30px;">
                <app-sidenav-list [showText]="isExpanded || isShowing" [navConfig]="subMenuconfig"/>
            </div>
        }
    </mat-sidenav>
    <div>
        <router-outlet />
    </div>
</mat-sidenav-container>
