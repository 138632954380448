
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [
    MatIconModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatInputModule
],
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.scss'
})
export class FilterComponent {

  @Input() columns: string[] = [];
  filters: Array<{ column: string; value: string; }> = [];

  @Output() filterApply = new EventEmitter<Array<{ column: string; value: string; }>>();
  @Output() filterClear = new EventEmitter<void>();

  addFilter() {
    if (this.filters.length < this.columns.length) {
      this.filters.push({ column: '', value: '' });
    } else {
      console.log('No se pueden agregar más filtros.');
    }
  }

  removeFilter(index: number) {
    this.filters.splice(index, 1);
    this.applyFilters();
  }

  applyFilters() {
    this.filterApply.emit(this.filters.filter(filter => filter.column && filter.value));
  }

  clearFilters() {
    this.filters = [];
    this.filterClear.emit();
  }

}
