import { Injectable } from '@angular/core';
import { ToolbarComponent } from '@components/toolbar/toolbar.component';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  toolbar: ToolbarComponent;

  private sidenavOpenSource = new BehaviorSubject<boolean>(false);
  sidenavOpen$ = this.sidenavOpenSource.asObservable();

  constructor() {}

  setToolbar(tb: ToolbarComponent) {
    this.toolbar = tb;
  }

  toggleSidenav() {
    this.sidenavOpenSource.next(!this.sidenavOpenSource.value);
  }

  openSidenav() {
    this.sidenavOpenSource.next(true);
  }

  closeSidenav() {
    this.sidenavOpenSource.next(false);
  }

  setLoggedIn(value: boolean) {
    this.toolbar.isLoggedIn = value;
  }
}
