<div class="content-container">
  <div style="text-align: right; margin: 10px;">
    <button mat-raised-button color="primary" (click)="navigateToAddForm()">
      <mat-icon>add</mat-icon>
      Agregar
    </button>
  </div>

  <app-filter [columns]="displayedColumns" (filterApply)="applyFilters($event)"
    (filterClear)="clearFilters()"></app-filter>
  <app-data-table [displayedColumns]="displayedColumns" [dataSource]="dataSource"></app-data-table>
</div>