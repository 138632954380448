import { Component, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';

import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';

import { FormArray, FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormFieldFx, NewForm } from '@models/form.model';

import { ActivatedRoute, Router } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { ContentModel } from '@models/content.model';

@Component({
  selector: 'app-form',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatCardModule,
    MatDatepickerModule,
    MatDividerModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatOptionModule,
    MatRadioModule,
    MatSelectModule,
    MatButtonModule
],
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss'
})
export class FormComponent {
  @Input() newForm!: NewForm;
  @Input() array!: FormArray;
  @ViewChildren(MatSelect) private readonly selects: QueryList<MatSelect>;
  @ViewChild(MatSelect, { static: true }) select: MatSelect;

  hidePassword: boolean = true;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly fb: FormBuilder
  ) { }

  get form() {
    return this.newForm;
  }

  addItem() {
    this.array.push(this.fb.group({
      descripcion: new FormControl('', [Validators.email]),
      isCc: new FormControl(),
      isCco: new FormControl()
    }));
  }

  delItem(i: ContentModel) {
    this.array.removeAt(i.id);
  }

  isError(field: FormFieldFx) {
    const control = this.newForm.newFormGroup.controls[field.target];
    if (control === undefined) {
      return false;
    }
    return control.invalid;
  }

  showMessageError(field: FormFieldFx): string {
    const control = this.newForm.newFormGroup.controls[field.target];
    if (control === undefined) {
      return '';
    }
    let errorMsg = [];
    const errorList = [
      {
        tipo: 'required',
        msg: '* Campo Obligatorio'
      },
      {
        tipo: 'email',
        msg: '* Formato de email inválido'
      },
      {
        tipo: 'pattern',
        msg: '* Campo no válido'
      },
      {
        tipo: 'rutInvalido',
        msg: '* Rut inválido'
      },
      {
        tipo: 'min',
        msg: `* Debe ser mayor o igual a ${control.getError('min') ? control.getError('min').min : ''}`
      },
      {
        tipo: 'max',
        msg: `* Debe ser menor o igual a ${control.getError('max') ? control.getError('max').max : ''}`
      }
    ];
    let isErrorGeneric = false;
    for (const e of errorList) {
      if (control.hasError(e.tipo)) {
        errorMsg.push(e.msg);
        isErrorGeneric = true;
      }
    }
    if (!isErrorGeneric && field.error !== '') {
      errorMsg = [];
      errorMsg.push(control.invalid ? field.error : '');
    }
    return errorMsg.toString();
  }

  onBlurAutocomplete(field: FormFieldFx) {
    const control = this.newForm.newFormGroup.controls[field.target];
    const value = control.value;
    if (value) {
      const valueInOpcion = field.opciones?.some(opc => opc === value);
      if (!valueInOpcion) {
        control.setValue("");
      }
    }
  }


}
