<div class="content-container">
    <mat-card class="content-card">
        <mat-card-content>
            <app-form [newForm]="fileNewForm"></app-form>
        </mat-card-content>
        <mat-card-actions class="card-actions" style="display: flex; justify-content: flex-end;">
            <button type="button" mat-raised-button color="primary" class="action-button"
                [disabled]="fileNewForm.newFormGroup.invalid || saved" (click)="saveFile()">
                Guardar
            </button>
            <button mat-raised-button class="action-button" (click)="cancelCreate()">
                Cancelar
            </button>
        </mat-card-actions>
    </mat-card>
</div>