import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { FormComponent } from '@manager/form/form.component';
import { NewForm } from '@models/form.model';
import { LoginService } from './services/login.service';
import { LoginRequest } from './model/login.model';
import { NavigationService } from '@services/navigation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    FormComponent,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    ReactiveFormsModule,
],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  loginFormGroup: FormGroup;
  loginFormModel: NewForm;
  intentandoLogin = false;

  constructor(
    private readonly loginService: LoginService,
    private readonly navService: NavigationService,
    private readonly router: Router,
  ) { }

  ngOnInit(): void {
    this.inicializarLoginForm();
  }

  private inicializarLoginForm(): void {
    this.loginFormGroup = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });

    this.loginFormModel = {
      title: 'Inicio de Sesión',
      formFields: [
        { target: 'email', title: 'Correo Electrónico', type: 'INPUT', size: 3 },
        { target: 'password', title: 'Contraseña', type: 'PASSWORD', size: 3 },
      ],
      newFormGroup: this.loginFormGroup,
    };
  }

  login() {
    if (this.loginFormGroup.valid) {
      console.log('Datos del formulario:', this.loginFormGroup.value);
      this.intentandoLogin = true
      const request: LoginRequest = {
        email: this.loginFormGroup.controls['email'].value,
        password: this.loginFormGroup.controls['password'].value,
      }
      this.loginService.login(request).subscribe((response: any) => {
        console.log(response);
        if(response?.data?.resultado === 0) {
          this.navService.setLoggedIn(true);
          this.router.navigate(['private'])
        }
      },(e) => {
        console.log(e);
      });
    } else {
      console.error('El formulario no es válido');
    }
  }

}
