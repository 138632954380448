import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DataTableComponent } from '@components/data-table/data-table.component';
import { FilterComponent } from '@manager/filter/filter.component';
import { ContentModel } from '@models/content.model';
import { CmsService } from '@services/cms.service';

@Component({
  selector: 'app-list',
  standalone: true,
  imports: [
    FilterComponent,
    DataTableComponent,
    MatButtonModule,
    MatIconModule
  ],
  templateUrl: './list.component.html',
  styleUrl: './list.component.scss'
})
export class ListComponent implements OnInit {

  displayedColumns: string[] = ['id', 'nombre', 'descripcion', 'autor', 'fecha', 'hora', 'valor', 'categoria', 'editar'];
  dataSource: MatTableDataSource<ContentModel>;

  constructor(private cmsService: CmsService, private router: Router) { }

  navigateToAddForm() {
    this.router.navigate(['/private/cms/manage/new']);
  }

  ngOnInit() {
    this.cmsService.getData().subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
    });
  }

  applyFilters(filters: Array<{ column: string; value: string; }>) {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const transformedFilters: Array<{ column: string; value: string; }> = JSON.parse(filter);
      return transformedFilters.every(f => {
        const dataValue = data[f.column].toString().toLowerCase();
        return dataValue.includes(f.value.toLowerCase());
      });
    };
    this.dataSource.filter = JSON.stringify(filters);
  }

  clearFilters() {
    this.dataSource.filter = '';
  }
  
}
