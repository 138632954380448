import { Routes } from "@angular/router";
import { AlertsComponent } from "./content-types/alerts/alerts.component";
import { BannersComponent } from "./content-types/banners/banners.component";
import { ImagesComponent } from "./content-types/images/images.component";
import { InformationComponent } from "./content-types/information/information.component";
import { NewsComponent } from "./content-types/news/news.component";
import { CreateComponent } from "./manage/create/create.component";
import { EditComponent } from "./manage/edit/edit.component";
import { ListComponent } from "./manage/list/list.component";

export const cmsRoutes: Routes = [
    {
        path: 'manage',
        children: [
            {
                path: 'list',
                component: ListComponent,  
            },
            {
                path: 'new',
                component: CreateComponent,
            },
            {
                path: 'edit/:id',
                component: EditComponent,
            },
        ]
    },
    { path: 'banners', component: BannersComponent },
    { path: 'alerts', component: AlertsComponent },
    { path: 'news', component: NewsComponent },
    { path: 'images', component: ImagesComponent },
    { path: 'information', component: InformationComponent },
];
