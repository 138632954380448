import { Component, OnInit } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidenavListComponent } from './sidenav-list/sidenav-list.component';
import { NavConfig } from '@models/nav-config.model';
import { NavigationService } from '@services/navigation.service';

@Component({
  selector: 'app-sidenav',
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    RouterModule,
    SidenavListComponent,
  ],
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit{

  constructor(private navService: NavigationService) {}

  ngOnInit() {
    this.navService.sidenavOpen$.subscribe((open) => {
      this.isExpanded = open;
      if (!open) {
        this.isShowing = false;
      }
    });
  }

  isExpanded = true;
  isShowing = false;
  config: NavConfig = {
    class: '',
    navList: [
      {
        isLink: true,
        target: '/private/dashboard',
        icon: 'dashboard',
        label: 'Dashboard'
      },
      {
        isLink: true,
        target: '/private/cms/manage/list',
        icon: 'view_list',
        label: 'Listar Todo'
      },
      {
        isLink: false,
        target: '',
        icon: 'settings',
        label: 'Gestionar Contenidos',
        class: 'parent',
      },
    ],
  };
  subMenuconfig: NavConfig = {
    class: 'submenu',
    hide: true,
    navList:[
      {
        isLink: true,
        target: '/private/cms/information',
        icon: 'info',
        label: 'Información',
      },
      {
        isLink: true,
        target: '/private/cms/banners',
        icon: 'campaign',
        label: 'Banners',
      },
      {
        isLink: true,
        target: '/private/cms/alerts',
        icon: 'warning',
        label: 'Alertas',
      },
      {
        isLink: true,
        target: '/private/cms/news',
        icon: 'article',
        label: 'Noticias',
      },
      {
        isLink: true,
        target: '/private/cms/images',
        icon: 'image',
        label: 'Imágenes',
      },
    ]
  };

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  showSubMenu() {
    this.subMenuconfig.hide = !this.subMenuconfig.hide;
    this.subMenuconfig.class = this.subMenuconfig.hide ? 'submenu' : 'submenu expanded';
  }
}
