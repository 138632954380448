import { Injectable } from '@angular/core';
import { LoginRequest } from '../model/login.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private url = '/seguridad/login';

  constructor(private readonly httpClient: HttpClient) { }

  login(request: LoginRequest) {
    return this.httpClient.post(`${environment.url.security}${this.url}`, request);
  }
}
