import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatLabel } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router } from '@angular/router';
import { NavigationService } from '@services/navigation.service';

@Component({
  selector: 'app-toolbar',
  standalone: true,
  imports: [
    MatToolbarModule,
    MatIconModule,
    MatLabel,
    MatButtonModule,
  ],
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss'
})
export class ToolbarComponent {
  @Input() isExpanded: boolean = true;
  isLoggedIn = false;

  constructor(private navService: NavigationService, private readonly router: Router) {
    this.navService.setToolbar(this);
  }

  toggleMenu() {
    this.navService.toggleSidenav();
  }

  navigateTo(target: string) {
    this.router.navigate([target]);

  }
}
