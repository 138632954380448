<mat-card-header>
    <mat-card-title>
        <h3>{{ newForm.title }}</h3>
    </mat-card-title>
</mat-card-header>
<mat-card-content>
    <form [formGroup]="newForm.newFormGroup">
        <mat-grid-list cols="{{newForm.cols ?? 12}}" rowHeight="90px" [gutterSize]="'10px'">
            @for (field of newForm.formFields; track $index) {
            <mat-grid-tile [colspan]="field.size">
                <div style="display: flex; flex-direction: column; justify-content: space-around; width: 100%;">
                    @switch (field.type) {
                    <!-- INPUT -->
                    @case ('INPUT') {
                    <mat-form-field appearance="outline" class="{{ field.type }}" [class.disable-block]="field.disabled"
                        fxFlex>
                        <mat-label>
                            {{field.title}}
                        </mat-label>
                        <input formControlName="{{ field.target }}" matInput placeholder="Agregar información" />
                        <mat-error [hidden]="isError(field)">{{showMessageError(field)}}</mat-error>
                    </mat-form-field>
                    }

                    <!-- NUMBER -->
                    @case ('NUMBER') {
                    <mat-form-field appearance="outline" class="{{ field.type }}" fxFlex>
                        <mat-label>
                            {{field.title}}
                        </mat-label>
                        <input type="number" formControlName="{{ field.target }}" matInput
                            placeholder="Agregar información" />
                        <mat-error [hidden]="isError(field)">{{showMessageError(field)}}</mat-error>
                    </mat-form-field>
                    }

                    <!-- INPUT EMAIL -->
                    @case ('EMAIL') {
                    <mat-form-field appearance="outline" class="{{ field.type }}" fxFlex>
                        <mat-label>
                            {{field.title}}
                        </mat-label>
                        <input formControlName="{{ field.target }}" matInput type="email" [email]="true"
                            placeholder="Agregar Email" />
                        <mat-error [hidden]="isError(field)">{{showMessageError(field)}}</mat-error>
                    </mat-form-field>
                    }

                    <!-- SELECT -->
                    @case ('SELECT') {
                    <mat-form-field appearance="outline" class="{{ field.type }}" fxFlex>
                        <mat-label>
                            {{field.title}}
                        </mat-label>
                        <mat-select [(value)]="field.target" formControlName="{{ field.target }}"
                            placeholder="{{ field.title }}">
                            @if (field.opciones !== undefined){
                            @for (item of field.opciones; track $index) {
                            <mat-option [value]="item">
                                {{ item }}
                            </mat-option>
                            }
                            }
                            @if (field.values !== undefined) {
                            @for (item of field.values; track $index) {
                            <mat-option [value]="item.value">
                                {{ item.title }}
                            </mat-option>
                            }
                            }
                        </mat-select>
                        <mat-error [hidden]="isError(field)">{{showMessageError(field)}}</mat-error>
                    </mat-form-field>
                    }

                    <!-- SELECTFX -->
                    @case ('SELECTFX') {
                    <mat-form-field appearance="outline" class="{{ field.type }}" fxFlex>
                        <mat-label>
                            {{field.title}}
                        </mat-label>
                        <mat-select (ngModelChange)="field?.selectChanged($event)" [(value)]="field.target"
                            formControlName="{{ field.target }}" placeholder="{{ field.title }}">
                            @for (item of field.opciones; track $index) {
                            <mat-option [value]="item">
                                {{ item }}
                            </mat-option>
                            }
                        </mat-select>
                        <mat-error [hidden]="isError(field)">{{showMessageError(field)}}</mat-error>
                    </mat-form-field>
                    }

                    <!-- DATEPICKER -->
                    @case ('DATEPICKER') {
                    <mat-form-field appearance="outline" class="{{ field.type }}" fxFlex>
                        <input [matDatepicker]="picker" formControlName="{{ field.target }}" matInput
                            placeholder="{{ field.title }}" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error [hidden]="isError(field)">{{showMessageError(field)}}</mat-error>
                    </mat-form-field>
                    }

                    <!-- TIME -->
                    @case ('TIME') {
                    <mat-form-field appearance="outline" class="{{ field.type }}" fxFlex>
                        <mat-label>
                            {{field.title}}
                        </mat-label>
                        <input type="time" formControlName="{{ field.target }}" matInput />
                        <mat-error [hidden]="isError(field)">{{showMessageError(field)}}</mat-error>
                    </mat-form-field>
                    }

                    <!-- RADIOBUTTON -->
                    @case ('RADIO') {
                    <mat-form-field appearance="outline" class="{{ field.type }}" fxFlex>
                        <mat-label>
                            {{field.title}}
                        </mat-label>
                        <mat-radio-group formControlName="{{ field.target }}">
                            @for (item of field.opciones; track $index) {
                            <mat-radio-button [value]="item" style="margin: 10px">
                                {{ item }}
                            </mat-radio-button>
                            }
                        </mat-radio-group>
                        <mat-error [hidden]="isError(field)">{{showMessageError(field)}}</mat-error>
                    </mat-form-field>
                    }

                    <!-- PASSWORD -->
                    @case ('PASSWORD') {
                    <mat-form-field appearance="outline" class="{{ field.type }}" fxFlex>
                        <mat-label>
                            {{field.title}}
                        </mat-label>
                        <input type="password" formControlName="{{ field.target }}" matInput
                            [type]="hidePassword ? 'password' : 'text'" />
                        <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword"
                            aria-label="Toggle password visibility">
                            <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                        </button>
                        <mat-error [hidden]="isError(field)">{{showMessageError(field)}}</mat-error>
                    </mat-form-field>
                    }
                    }
                </div>
            </mat-grid-tile>
            }
        </mat-grid-list>
    </form>
</mat-card-content>