import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatList, MatListItem, MatListItemIcon, MatNavList } from '@angular/material/list';
import { Router } from '@angular/router';
import { NavListItem } from '@models/nav-config.model';

@Component({
  selector: 'app-sidenav-list-item',
  standalone: true,
  imports: [
    CommonModule,
    MatIcon,
    MatList,
    MatListItem,
    MatListItemIcon,
    MatNavList,
  ],
  templateUrl: './sidenav-list-item.component.html',
  styleUrl: './sidenav-list-item.component.scss'
})
export class SidenavListItemComponent {
  @Input() model: NavListItem;
  @Input() showText = false;
  @Output() itemClicked = new EventEmitter();

  constructor(private readonly router: Router) {
  }

  navigateTo(link: string) {
    this.router.navigate([link]);
  }

  onItemClicked() {
    this.itemClicked.emit();
  }
}
