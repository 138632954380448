import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { FormComponent } from '@manager/form/form.component';
import { NewForm } from '@models/form.model';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { CmsService } from '@services/cms.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-create',
  standalone: true,
  imports: [
    FormComponent,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
  ],
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
  fileFormGroup: FormGroup;
  fileNewForm: NewForm;
  saved = false;

  constructor(
    private location: Location,
    private cmsService: CmsService
  ) { }

  ngOnInit(): void {
    this.inicializarForm();
  }

  private inicializarForm(): void {
    this.fileFormGroup = new FormGroup({
      nombre: new FormControl('', [Validators.required]),
      descripcion: new FormControl('', [Validators.required]),
      autor: new FormControl('', [Validators.required]),
      fecha: new FormControl('', [Validators.required]),
      valor: new FormControl('', [Validators.required]),
    });

    this.fileNewForm = {
      title: 'Nuevo Contenido',
      formFields: [
        { target: 'nombre', title: 'Nombre', type: 'INPUT', size: 4 },
        { target: 'descripcion', title: 'Descripción', type: 'INPUT', size: 8 },
        { target: 'autor', title: 'Autor', type: 'INPUT', size: 3 },
        { target: 'fecha', title: 'Fecha', type: 'DATEPICKER', size: 3 },
        { target: 'valor', title: 'Valor', type: 'INPUT', size: 3 },
      ],
      newFormGroup: this.fileFormGroup,
    };
  }

  saveFile(): void {
    if (this.fileFormGroup.valid) {
      this.saved = true;
      const newRecord = {
        ...this.fileFormGroup.value
      };
      this.cmsService.addData(newRecord);
      console.log('Nuevo registro creado:', newRecord);
      this.location.back();
      this.saved = false;
    } else {
      console.error('El formulario no es válido');
    }
  }

  cancelCreate() {
    this.location.back();
  }

}
