<div class="content-container">
  <mat-card class="content-card">
    <mat-card-content>
      <app-form [newForm]="loginFormModel"></app-form>
    </mat-card-content>
    <mat-card-actions class="card-actions" style="display: flex; justify-content: flex-end;">
      <button type="submit" mat-raised-button color="primary" class="action-button"
        [disabled]="!loginFormGroup.valid || intentandoLogin" (click)="login()">
        Iniciar sesión
      </button>
    </mat-card-actions>
  </mat-card>
</div>