<mat-card class="content-card">
    <mat-card-content>
        <app-form [newForm]="newForm"></app-form>
    </mat-card-content>
    <mat-card-actions class="card-actions" style="display: flex; justify-content: flex-end;">
        <button type="button" mat-raised-button color="primary" class="action-button"
            [disabled]="formGroup.invalid || saved" (click)="saveFile()">
            Crear
        </button>
        <button mat-raised-button class="action-button" (click)="cancelCreate()">
            Cancelar
        </button>
    </mat-card-actions>

    <mat-card>
        <mat-card-content>
            <app-filter [columns]="displayedColumns" (filterApply)="applyFilters($event)"
                (filterClear)="clearFilters()"></app-filter>
        </mat-card-content>

        <mat-card-content>
            <app-data-table [displayedColumns]="displayedColumns" [dataSource]="dataSource"></app-data-table>
        </mat-card-content>
    </mat-card>
</mat-card>