import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ContentManagerComponent } from '@components/manager/content-manager/content-manager.component';
import { ContentModel } from '@models/content.model';
import { NewForm } from '@models/form.model';
import { CmsService } from '@services/cms.service';

@Component({
  selector: 'app-banners',
  standalone: true,
  imports: [
    ContentManagerComponent
  ],
  templateUrl: './banners.component.html',
  styleUrl: './banners.component.scss'
})
export class BannersComponent implements OnInit{
  newForm: NewForm;
  displayedColumns: string[] = ['id', 'nombre', 'descripcion', 'autor', 'fecha', 'valor', 'categoria', 'editar'];
  dataSource: MatTableDataSource<ContentModel>;
  formGroup: FormGroup;

  constructor(private cmsService: CmsService) { }

  ngOnInit() {
    this.cmsService.getData().subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
    });

      this.formGroup = new FormGroup({
      nombre: new FormControl('', [Validators.required]),
      descripcion: new FormControl('', [Validators.required]),
      autor: new FormControl('', [Validators.required]),
      fecha: new FormControl('', [Validators.required]),
      valor: new FormControl('', [Validators.required]),
      categoria: new FormControl('Banners', [Validators.required]),
    });

    this.newForm = {
      title: 'Añadir Banner',
      formFields: [
        { target: 'nombre', title: 'Nombre', type: 'INPUT', size: 4},
        { target: 'descripcion', title: 'Descripción', type: 'INPUT', size: 8},
        { target: 'autor', title: 'Autor', type: 'INPUT', size: 3},
        { target: 'fecha', title: 'Fecha', type: 'DATEPICKER', size: 3},
        { target: 'valor', title: 'Valor', type: 'INPUT', size: 3}
      ],
      newFormGroup: this.formGroup
    };
  }
}
