import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-cms',
  standalone: true,
  imports: [
    RouterModule,
  ],
  template: `<router-outlet/>`,
  styleUrl: './cms.component.scss'
})
export class CmsComponent {

}
